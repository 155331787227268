import { createReduxStore } from "@skryv/core-react/src/core/store";

import { burgerprofielConfiguration, dossierCreationToggles } from "./reducers";

import apiMiddleware from "@skryv/core-react/src/store/api";

export default createReduxStore(
  { burgerprofielConfiguration, dossierCreationToggles },
  undefined,
  [apiMiddleware]
);
