export default {
  title: "Departement Mobiliteit en Openbare Werken",
  contact: "https://www.mow-contact.be/",
  address: {
    buildingName: "Marie-Elisabeth Belpairegebouw",
    streetAndNumber: "Simon Bolivarlaan 17",
    zipCode: "1000",
    city: "Brussel",
    country: "België",
  },
  phone: "1700",
  lat: "50.8612903",
  long: "4.3543241",
  mapUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5036.746407289712!2d4.354324077153771!3d50.86129027167351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c3913c5340ab%3A0x40be55568ae8abc9!2sSimon%20Bolivarlaan%2017%2C%201030%20Schaarbeek%2C%20Belgi%C3%AB!5e0!3m2!1snl!2sie!4v1732189113768!5m2!1snl!2sie",
};
